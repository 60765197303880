import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import LiftedTopScreen from '../../../../ScreenWidthContainer/viewer/skinComps/LiftedTopScreen/LiftedTopScreen';
import HeaderContainer from '../../HeaderContainer';

const LiftedTopScreenHeader: React.FC<Omit<
  IHeaderContainerProps,
  'skin'
>> = props => (
  <HeaderContainer {...props} skin={LiftedTopScreen}></HeaderContainer>
);

export default LiftedTopScreenHeader;
